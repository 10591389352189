.marketPlaceMain {
  padding: 70px 0;
  font-family: earlyGameBoy;
}
.marketDetail  {
font-family: earlyGameBoy;
}
.marketPlaceMain .dataOverviewMain {
  color: white;
  display: flex;
  max-width: 500px;
  margin: auto;
  /* font-size: 13px; */
  justify-content: space-between;
  padding: 15px;
  border: 2px solid #ffd800;
  border-radius: 10px;
  margin-bottom: 20px;
}
.marketPlaceMain .dataOverviewMain .data {
  text-align: center;
}
.marketPlaceMain .dataOverviewMain .data h4{
  margin: 0;
  font-size: 13px;
}
.marketPlaceMain .dataOverviewMain .data p{
  font-size: 13px;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
}
.marketPlaceMain .marketMain {
  border: 2px solid #ffd800;
  padding: 8px;
  border-radius: 10px;
}
.marketPlaceMain .marketMain .itemBtnDiv {
  text-align: center;
  margin-bottom: 10px;
}
.marketPlaceMain .marketMain .itemBtnDiv button{
  border: 2px solid #ffd800;
  padding: 8px 20px;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.marketPlaceMain .marketMain .dropDownCard .card-body{
  padding: 0;
}
.marketPlaceMain .marketMain .title {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  text-align: left;
  border: 2px solid #ffd800;
  padding: 10px;
  border-radius: 10px;
}
.marketPlaceMain .marketMain .dropDownCard .contentMain {
  max-height: 150px;
  overflow-y: auto;
  /* margin-bottom: 10px; */
}
.marketPlaceMain .marketMain .dropDownCard .contentMain .selectContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: transparent;
  color: white;
  border: none;
}
.marketPlaceMain .marketMain .dropDownCard .contentMain .selectContent span{
  font-size: 12px;
  font-weight: bold;
}
.marketPlaceMain .marketMain .dropDownCard .card-body input{
  background: transparent;
  border: 1px solid #6c757d;
  border-left: 1px solid transparent;
  color: white;
  padding-left: 0;
}
.marketPlaceMain .marketMain .dropDownCard .card-body input:focus{
  box-shadow: none;
}
.marketPlaceMain .marketMain .dropDownCard .card-body .searchBtn{
  border-right: 1px solid transparent;
}
.marketPlaceMain ::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}
.marketPlaceMain  {
  scrollbar-width: thin;
  /* scrollbar-color: #90A4AE #CFD8DC; */
}
.marketPlaceMain ::-webkit-scrollbar-track {
  background: #CFD8DC;
  border-radius: 10px ;
}
.marketPlaceMain ::-webkit-scrollbar-thumb {
  background-color: #90A4AE ;
  border-radius: 6px;
  /* border: 3px solid #CFD8DC; */
}
.marketPlaceMain button:focus{
  box-shadow: none;
}
.marketPlaceMain .marketMain .priceMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.marketPlaceMain .marketMain .priceMain .col1{
  display: flex;
  flex-wrap: wrap;
  color: white;
  text-transform: uppercase;
}
.marketPlaceMain .marketMain .priceMain .col1 p{
  font-size: 12px;
  margin-bottom: 0;
}
.marketPlaceMain .marketMain .priceMain .col1 .item{
  margin-right: 20px;
}
.marketPlaceMain .marketMain .priceMain .col2 .dropdown-toggle:hover{
  color: white;
}
.marketPlaceMain .marketMain .priceMain .col2 .dropdown-menu{
  background-color: transparent;
  left:unset !important;
  right: 0 !important;
}
.marketPlaceMain .themeBtn {
  border: 2px solid #ffd800;
  padding: 8px 20px;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.marketPlaceMain .marketMain .nftCardMain .nftCard {
  border: 2px solid #ffd800;
  /* padding: 10px; */
  border-radius: 10px;
  color: white;
  font-size: 10px;
  height: 280px;
  overflow: hidden;
  margin-bottom: 10px;
}

.marketPlaceMain .marketMain .nftCardMain .nftCard:hover{
  border: 2px solid #ffffff;
  color: #ffd800 !important;
  box-shadow: 2px 2px 2px #ffffff;
}
.bg-Dark {
  background-color: #1a0e28 !important;
}
.marketPlaceMain .marketMain .nftCardMain .nftCard .imgDiv{
  height: 74%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-bottom: 1px solid #ffd800;
}
.marketPlaceMain .marketMain .nftCardMain .nftCard .imgDiv img{
  min-width: 100%;
  max-width: 100%;
  height: auto;
}
.marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain{
  padding: 10px;
  height: 26%;
}
.marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .id{
  margin: 0;
  font-weight: bold;
}
.marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv{
  display: flex;
  justify-content: space-between;
}
.marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv p{
  margin: 0;
  text-transform: uppercase;
  margin: 4px 0;
}
.marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv .amount{
  font-weight: bold;
}
.marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv .amount img{
  height: 14px;
}

.marketDetail {
  margin: 10px 0;
}
.marketDetail .mainImgDiv {
  border: 2px solid #ffd800;
  /* padding: 10px; */
  border-radius: 10px;
  overflow: hidden;
}
.marketDetail .mainImgDiv img{
  width: 100%;
  height: auto;
  border-radius: 7px;
}
.marketDetail .themeBorder {
  border: 2px solid #ffd800;
  border-radius: 10px;
}
.marketDetail .nftNameMain {
  color: white;
  padding: 18px;
}
.marketDetail .nftNameMain h6{
  text-transform: uppercase;
  font-size: 15px;
}
.marketDetail .nftNameMain .address{
  
}
.marketDetail .nftNameMain .address span{
  font-size: 15px;
}
.marketDetail .nftNameMain .address img{
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin-right: 5px;
}
.marketDetail .buyMain {
  color: white;
  padding: 18px;
}
.marketDetail .buyMain .col1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.marketDetail .buyMain .col1 h6{
  margin-bottom: 0;
}
.marketDetail .buyMain .col1 .mLink{
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 14px;
  display: inline-block;
}
.marketDetail .buyMain .col1 .mLink img{
  margin-left: 5px;
  width: 25px;
}
.marketDetail .buyMain .col1 .priceDiv{
 margin-bottom: 10px;
 margin-top: 10px;
}
.marketDetail .buyMain .col1 .priceDiv .eth{
 width: 15px;
}

.marketDetail .buyMain .col1 .buyBtn{
 border: 2px solid #ffd800;
  padding: 5px 10px;
  background: #fdd803;
  width: 100%;
  border-radius: 10px;
  color: black;
  font-size: 15px;
  text-transform: uppercase;
}
.marketDetail .buyMain .col1 .colInner{
  
}
.marketDetail .buyMain .col1 .offerBtn{
 border: 2px solid #ffd800;
  padding: 5px 10px;
  width: 100%;
  border-radius: 10px;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
}
.marketDetail .attributeMain {
  padding: 18px;
  color: white;
}
.marketDetail .attributeMain .attRow{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.marketDetail .attributeMain .attRow .attrCard .title {
  font-size: 13px;
}
.marketDetail .attributeMain .attRow .attrCard img{
  max-height: 22px  ;
}
.marketDetail .attributeMain .attRow .attrCard .r3{
  font-size: 12px;
}
.marketDetail .attributeMain .attRow .attrCard{
  padding: 10px;
  width: 49%;
  display: inline-block;
  font-size: 13px;
  /* margin: 0 2px; */
  margin-bottom: 12px;

}
.marketDetail .attributeMain .attRow .attrCard .r2{
  font-size: 13px;
}
.text-bold {
  font-weight: bold;
}
.marketDetail .attributeMain .attRow .attrCard p{
 margin-bottom: 0;
}
.jc-sb {
  justify-content: space-between;
}
.marketDetail .collectionInfo {
  padding: 18px;
  color: white;
}
.marketDetail .collectionInfo h5{
  font-size: 18px;
}
.marketDetail .collectionInfo .name{
  font-weight: 500;
  color: white;
  margin: 10px 0;
  font-size: 14px;
}
.marketDetail .collectionInfo .name img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 4px;
}
.marketDetail .collectionInfo .desc{
  margin-bottom: 0;
  font-size: 12px;
}
.marketDetail .tokenInfo {
  padding: 18px;
  color: white;
  font-size: 12px;
}
.marketDetail .tokenInfo h5{
    font-size: 18px;
}
.marketDetail .tokenInfo .imgDiv img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.marketDetail .tokenInfo .address{
  font-weight: bold;
  color: #ffd800;
  text-decoration: none;
  display: flex; 
  align-items: center;
}
.marketDetail .tokenInfo .address:hover{
  color: #ffd800;
  text-decoration: underline;
}
.marketDetail .tokenInfo .imgDiv .val{
  font-weight: 500;
}

.marketMain .itemTableMain {
  color: white;
}
.marketMain .itemTableMain thead tr td{
  padding-bottom: 30px;
}
.marketMain .itemTableMain table tr td{
  color: white;
  vertical-align: middle;
  font-size: 12px !important;
  width: max-content;
}
.marketMain .itemTableMain .eventDiv{
  display: flex;
}
.marketMain .itemTableMain .eventDiv img{
  width: 20px;
  margin-right: 7px;
}
.marketMain .itemTableMain .itemDiv{
  display: flex;
  align-items: center;
  width: max-content;
}
.marketMain .itemTableMain .itemDiv .imgdiv{
  width: 48px;
  height: 48px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
}
.marketMain .itemTableMain .itemDiv .imgdiv img{
  width: 100%;
}
.marketMain .itemTableMain .priceDiv img{
  height: 20px;
  margin-right: 5px;
  width: max-content;
}
.marketMain .itemTableMain .address{
  color: #fdd803;
  width: max-content;
}



@media(max-width:576px) {
  .marketPlaceMain .marketMain .nftCardMain .nftCard {
      max-width: 350px;
      margin: auto;
      height:auto;
      margin-bottom: 15px;
  }
  .marketDetail .collectionInfo .desc {
    font-size: 10px;
  }
  .marketDetail .tokenInfo {
    font-size: 10px;
  }

  .marketDetail .nftNameMain h6 {
    font-size: 14px;
  }
  .marketDetail .nftNameMain .address span{
    font-size: 14px;
  }
  .marketDetail .buyMain .col1 .colInner h5 {

    font-size: 13px;
  }
  .marketDetail .buyMain .col1 .offerBtn {
    font-size: 11px;
  }
  .marketDetail .buyMain .col1 .buyBtn {
    font-size: 11px;
  }
  .marketDetail .buyMain .col1 .mLink {
    font-size: 12px;
  }
  .marketDetail .attributeMain .attRow .attrCard {
    font-size: 12px;
  }
  .marketDetail .attributeMain .attRow .attrCard .title {
    font-size: 12px;
  }
  .marketDetail .attributeMain .attRow .attrCard .r2 {
    font-size: 12px;
  }
  .marketDetail .attributeMain h5 {
    font-size: 16px;
  }
}
@media(max-width:768px) {
  .marketDetail .attributeMain .attRow .attrCard {
    width: 100%;
  }
}

.showmore:hover{
  background-color: #fdd803 !important;
  border: 2px solid #ffffff !important;
}
@media (max-width:1200px) and (min-width:992px) {
  .marketPlaceMain .marketMain .nftCardMain .nftCard {
    font-size: 9px;
  }
}
@media (max-width:992px) and (min-width:768px) {
  .marketPlaceMain .marketMain .title {
    font-size: 10px;
  }
}